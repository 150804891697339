<template>
<div class="row row-custom" v-if="Category">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل صانع المحتوى
        </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                  <tr><td>الاسم بالعربي </td><td>:</td><td>{{Category.category.name.ar}}</td></tr>
                 <tr><td>الاسم بالانجليزي</td><td>:</td><td>{{Category.category.name.en}}</td></tr>

                 <tr>
                     <td>صورة التصنيف</td><td>:</td><td>
                     <template v-if="Category.category.img">
                     <img width="100" height="100" :src="Category.category.img"/>
                     </template>
                     <template v-else>
                        <span>لايوجد</span>
                     </template>
                     </td>
                </tr>
                  <tr>
                  <td>اسم التصنيف الفرعي بالعربي</td>
                  <td>:</td>
                  <td>
                    <template v-if="Category.sub_category">
                      {{Category.sub_category.name.ar}}
                     </template>
                     <template v-else>
                        <span>لايوجد</span>
                     </template>
                  </td>
                  </tr>
                  <tr>
                  <td>اسم التصنيف الفرعي بالانجليزي</td>
                  <td>:</td>
                  <td>
                    <template v-if="Category.sub_category">
                      {{Category.sub_category.name.en}}
                     </template>
                     <template v-else>
                        <span>لايوجد</span>
                     </template>
                  </td>
                  </tr>
                  <tr>
                     <td>صورة التصنيف الفرعي</td><td>:</td><td>
                     <template v-if="Category.sub_category && Category.sub_category.img">
                     <img width="100" height="100" :src="Category.sub_category.img"/>
                     </template>
                     <template v-else>
                        <span>لايوجد</span>
                     </template>
                     </td>
                </tr>

             </table>
            </div>                      
        </div>

    </div>

    </div>
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
export default {
      computed:{
    ...mapState({
        Category:state=>state.admin.categories.ProductCategory.data,
        }),

      },
      methods:{
  
      },
     beforeRouteEnter(to,from,next){    
        const id = to.params.id;
        const product_uuid=to.params.product_uuid;
        store.dispatch('admin/fetchProductCategory', {id:id,product_uuid:product_uuid}).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Tajawal';
}
.card .card-body{
    font-size: 14px;
    font-family: 'Tajawal';
    font-weight: 600;
}
</style>